import Player from 'features/player/components/Player'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { use100vh } from 'react-div-100vh'

export default function PlayPage() {
  const router = useRouter()
  const minHeight = use100vh()

  const { code, presentationId } = router.query
  return (
    <div className="custom-theme">
      <div
        style={{ height: minHeight }}
        className="bg-background text-main bg-image"
      >
        <Player code={code} presentationId={presentationId} />
      </div>
    </div>
  )
}

export async function getServerSideProps({ locale, req }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      host: req?.headers?.host,
    },
  }
}
