import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import PlayPage from 'pages/play'

import 'firebase/firestore'

export default function HomePage({ host }) {
  const isPlayHost = host === process.env.NEXT_PUBLIC_PLAYER_HOST
  const router = useRouter()

  useEffect(() => {
    if (!isPlayHost) {
      router.replace('/auth/register')
    }
  }, [])

  if (isPlayHost) {
    return <PlayPage />
  }

  return null
}

export async function getServerSideProps({ locale, req }) {
  return {
    props: {
      ...(await serverSideTranslations(locale)),
      host: req?.headers?.host,
    },
  }
}
