import { useEffect, useState } from 'react'
import axios from 'axios'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Input from 'components/Input'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

export default function ConnectForm({ code = '', uid, presentationId }) {
  const { t } = useTranslation()
  const router = useRouter()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const { register, handleSubmit } = useForm({
    defaultValues: {
      code,
    },
  })

  const onSubmit = async ({ code: gameCode }) => {
    setLoading(true)
    try {
      const res = await axios.post('/api/player/join-presentation', {
        code: gameCode,
        uid,
        presentationId,
      })
      global.dataLayer.push({
        event: 'logEvent',
        eventType: 'User Joined Presentation',
        eventProperties: {
          presentationId: res.data.presentationId,
          sessionId: res.data.sessionId,
        },
      })
    } catch (err) {
      setError(err?.response?.data?.code || 'default')
    } finally {
      setLoading(false)
    }

    if (code) {
      router.replace(router.pathname, undefined, { shallow: true })
    }
  }

  useEffect(() => {
    if (code || presentationId) {
      onSubmit({ code, presentationId })
    }
  }, [])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="mb-4">
          <Input
            {...register('code')}
            type="number"
            inputMode="decimal"
            className="form-input rounded text-center"
            placeholder={t('user.connectForm.code')}
            required
            disabled={loading}
            size="lg"
          />
          {error && (
            <div className="text-center text-red-600">
              {t(`user.connectForm.error.${error}`)}
            </div>
          )}
        </div>
        <Button
          variant="success"
          type="submit"
          block
          loading={loading}
          size="lg"
        >
          {t('user.connectForm.connect')}
        </Button>
      </form>
    </div>
  )
}
