import React from 'react'
import { Fade } from 'react-awesome-reveal'

import Logo from 'components/Logo'

import 'firebase/firestore'

import ConnectForm from './components/ConnectForm'
import Session from './components/Session'

export default function User({ code, sessionId, uid, presentationId }) {
  return (
    <div className="h-full">
      <>
        {(code || !sessionId) && (
          <Fade className="flex h-full flex-col justify-center p-4">
            <div className="mx-auto flex h-full w-full max-w-xl flex-col">
              <div className="flex h-1/3 items-center justify-center">
                <Logo />
              </div>
              <div className="flex h-1/3 flex-col justify-center">
                <ConnectForm
                  code={code}
                  uid={uid}
                  presentationId={presentationId}
                />
              </div>
            </div>
          </Fade>
        )}
        {sessionId && !code && <Session sessionId={sessionId} uid={uid} />}
      </>
    </div>
  )
}
