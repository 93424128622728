import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import ReactCanvasConfetti from 'react-canvas-confetti'

import Button from 'components/Button'

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

export default function Points({ sessionUser }) {
  const { t } = useTranslation()
  const place = sessionUser?.place
  const [fire, setFire] = useState(false)

  useEffect(() => {
    if (place === 1) {
      setTimeout(() => {
        setFire(true)
      }, 1000)
    }
  }, [])

  const handleClick = () => {
    if (place === 1) {
      setFire(false)
      setTimeout(() => {
        setFire(true)
      }, 1)
    }
  }

  return (
    <div className="flex flex-col justify-center p-4 h-full max-w-xl mx-auto w-full">
      <div className="animate__animated animate__tada">
        <Button variant="success" size="xl" onClick={handleClick} block>
          {t('presentation.finish.place', { place })}
        </Button>
      </div>
      <ReactCanvasConfetti style={canvasStyles} fire={fire} />
    </div>
  )
}
