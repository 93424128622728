import React from 'react'
import { useTranslation } from 'next-i18next'
import { useFirestore, useFirestoreDocData } from 'reactfire'

export default function Slide({
  presentation,
  presentationId,
  sessionRef,
  slideId,
  uid,
  theme,
}) {
  const { t } = useTranslation()
  const firestore = useFirestore()

  const slideRef = firestore
    .collection('presentations')
    .doc(presentationId)
    .collection('slides')
    .doc(slideId)

  const { data: slide } = useFirestoreDocData(slideRef)

  const sessionSlideRef = sessionRef.collection('slides').doc(slideId)

  const { data: sessionSlide } = useFirestoreDocData(sessionSlideRef)

  if (!slide?.type) {
    return t('common.loading')
  }

  const path = {
    question: `question/${slide.questionType}`,
    poll: `poll/${slide.pollType}`,
    content: `content/text`,
  }[slide.type]

  const Component = require(
    `features/slides/${path}/components/Challenge`,
  ).default

  return (
    <Component
      presentation={presentation}
      sessionRef={sessionRef}
      sessionSlide={sessionSlide}
      sessionSlideRef={sessionSlideRef}
      slide={slide}
      slideId={slideId}
      slideRef={slideRef}
      uid={uid}
      theme={theme}
    />
  )
}
