import { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import ReactCanvasConfetti from 'react-canvas-confetti'

import Button from 'components/Button'

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
}

export default function Finish() {
  const { t } = useTranslation()
  const [fire, setFire] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFire(true)
    }, 1000)
  }, [])

  const handleClick = () => {
    setFire(false)
    setTimeout(() => {
      setFire(true)
    }, 1)
  }

  return (
    <div className="mx-auto flex h-full w-full max-w-xl flex-col justify-center p-4">
      <div className="animate__animated animate__tada">
        <Button variant="success" size="xl" onClick={handleClick} block>
          {t('presentation.finish.thanks')}
        </Button>
      </div>
      <ReactCanvasConfetti style={canvasStyles} fire={fire} />
    </div>
  )
}
