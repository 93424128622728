import { useEffect, useState } from 'react'
import firebase from 'firebase'
import { Trans } from 'next-i18next'
import { DotLoader as Loader } from 'react-spinners'
import { useAuth, useFirestore, useUser } from 'reactfire'

import Logo from 'components/Logo'

import 'firebase/auth'
import 'firebase/firestore'

import User from './components/User'

export default function Player({ code, presentationId }) {
  const [userProfile, setUserProfile] = useState()
  const [userStatus, setUserStatus] = useState()
  const auth = useAuth()
  const firestore = useFirestore()
  const user = useUser()
  const uid = user?.data?.uid

  useEffect(() => {
    auth.onAuthStateChanged((newUser) => {
      if (newUser) {
        const userRef = firestore.collection('players').doc(newUser.uid)

        userRef.onSnapshot((doc) => {
          setUserProfile(doc.data())
          setUserStatus('success')
        })

        return
      }

      async function signIn() {
        try {
          const res = await auth.signInAnonymously()
          const userRef = firestore.collection('players').doc(res.user.uid)

          userRef.set({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })

          userRef.onSnapshot((doc) => {
            setUserProfile(doc.data())
            setUserStatus('success')
          })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }

      signIn()
    })
  }, [])

  const loadingConditions = [!!uid, uid && userStatus === 'success']
  const isLoading = loadingConditions.includes(false)
  const sessionId = userProfile?.sessionId

  return (
    <div className="relative z-10 mx-auto flex h-full flex-col">
      <div className="flex-1">
        {isLoading && (
          <div className="flex h-full flex-col items-center justify-center">
            <Logo />
            <div className="mt-8 flex justify-center text-blue-600">
              <Loader size={80} color="currentColor" />
            </div>
          </div>
        )}
        {!isLoading && (
          <User
            sessionId={sessionId}
            userProfile={userProfile}
            uid={uid}
            code={code}
            presentationId={presentationId}
          />
        )}
      </div>
      <footer
        id="footer"
        className="bg-fade text-main py-2 text-center text-sm text-gray-500 backdrop-blur-sm"
      >
        <Trans i18nKey="common.madeWith">
          <a
            href="https://unislide.io/?utm_medium=website&utm_source=player"
            className="underline"
          >
            unislide.io
          </a>
        </Trans>
      </footer>
    </div>
  )
}
